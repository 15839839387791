// FB 登入
function authFb(state) {
  const url = `https://www.facebook.com/v15.0/dialog/oauth?client_id=${process.env.VUE_APP_FB_APPID}&redirect_uri=${process.env.VUE_APP_ORIGINURL}/redirectFb&scope=public_profile,email,groups_access_member_info,publish_to_groups,pages_manage_posts,pages_read_user_content,pages_read_engagement,pages_show_list&state=${state}`
  console.log(url)
  window.location.replace(url)
}
// 系統的 FB 相關登入
function fbLogin(code, state) {
  const fbLoginApi = `${process.env.VUE_APP_API}/apps/fb/loginCallback?code=${code}&state=${state}`
  const result = new Promise((resolve, reject) => {
    $.ajax({
      type: "POST",
      async: true,
      url: fbLoginApi,
      success: resolve,
      error: reject,
    });
  })
  return result
}

export { authFb, fbLogin }