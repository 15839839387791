/** fb 使用者可以用 https://twingo1.uniten.com.tw/authFb/{storeId} 此連結放置頂文讓顧客強制登入授權 */
<template>
  <div
    class="tw-bg-main d-flex justify-content-center align-items-center"
    style="height: 100vh"
  >
    <p class="p-3 alert-danger d-inline-block fw-bolder">Fb 用戶請稍後...</p>
  </div>
</template>

<script>
// api
import { authFb } from '@/methods/API/fbApi'

export default {
  data() {
    return {
      storeId: null
    }
  },
  created() {
    this.storeId = this.$route.params.storeId
    this.fbLogin()
  },
  methods: {
    fbLogin() {
      window.localStorage.setItem('identity', `customer,storeId:${this.storeId}`)
      authFb("login")
    }
  },
}
</script>